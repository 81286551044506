<template>
  <section class="invoice-preview-wrapper">
    <div class="position-relative">
      <b-card class="invoice-preview-card">
        <div class="d-flex justify-content-between flex-wrap">
          <h2 class="my-auto">Gestion des permissions <br /></h2>
          <b-button
            class="mt-1 float-right my-auto"
            variant="primary"
            @click="createFormulaire"
          >
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Nouvelle fiche</span>
          </b-button>
        </div>
      </b-card>

      <b-card class="mt-1"> toto </b-card>
    </div>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  VBTooltip,
  BFormSelect,
  BDropdownDivider,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import { mapActions } from "vuex";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import { $themeColors } from "@themeConfig";
import vSelect from "vue-select";
import TipsModalCreateFromLastRecord from "@/components/TipsModalCreateFromLastRecord.vue";
import DateRangeWidget from "@/components/DateRangeWidget.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import utilsService from "@/services/utils/utils.service";

import formulaireStoreModule from "@/store/formulaire";
import etablissementsStroreModule from "@/store/etablissements";
import usersStoreModule from "@/store/users";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

import { buildFiltersForRequest } from "@/helpers/http-utils";

const mockData = {
  formstatagences: [],
  total: 3,
};

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BCardBody,
    BCardHeader,
    flatPickr,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormSelect,
    BDropdownDivider,
    BCardTitle,
    BCardText,

    AppCollapse,
    AppCollapseItem,

    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    TipsModalCreateFromLastRecord,
    DateRangeWidget,
    PaginationComponent,
    ToastificationContent,
    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  setup() {
    const requiredStoreModules = [
      { path: "formulaire", module: formulaireStoreModule },
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "users", module: usersStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);
    const { isEtablissementUser, isAdminEtablissement, isAgentEtablissement } =
      utilsService.currentUserUtils();

    const departementsList = utilsService.getDepartements();
    const communesList = utilsService.getCommunes();

    return {
      departementsList,
      communesList,
      isAdminEtablissement,
      isAgentEtablissement,
      isEtablissementUser,
      requiredStoreModules,
    };
  },
  data() {
    return {
      etablissementsList: [],
      isLoadingEtablissementsList: true,
      chartOptions: {
        chart: {
          stacked: true,
          type: "bar",
          toolbar: { show: false },
        },
        grid: {
          padding: {
            top: -20,
            bottom: -10,
          },
          yaxis: {
            lines: { show: false },
          },
        },
        xaxis: {
          categories: ["a", "b", "b"],
          labels: {
            style: {
              colors: "#6E6B7B",
              fontSize: "0.86rem",
              fontFamily: "Montserrat",
            },
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          show: true,
        },
        dataLabels: {
          enabled: true,
        },
        colors: [$themeColors.primary, $themeColors.warning],
        plotOptions: {
          bar: {
            columnWidth: "17%",
            endingShape: "rounded",
          },
          distributed: true,
        },
        yaxis: {
          labels: {
            style: {
              colors: "#6E6B7B",
              fontSize: "0.86rem",
              fontFamily: "Montserrat",
            },
          },
        },
      },
      series: [
        {
          name: "Fiches",
          data: [95, 177, 84],
        },
      ],
      loadingExport: false,

      loading: false,
      statsLoad: null,

      lastestRecordedForm: null,

      filterBy: {
        periode: {},
        reference: null,
        firstname: null,
        lastname: null,
        nomEtablissement: null,
        commissariat: null,
        annee: null,
        mois: null,
        dateArrivee: null,
        date: null,
      },

      periode: null,
      reference: null,
      firstname: null,
      lastname: null,

      curPage: 1,
      perPage: 20,
      query: {
        options: {},
        filter: {},
      },
      tableColumns: [],

      tableData: [],
      tableDataCommissariats: [],

      filteredTableData: [],
      formTitle: this.$route.params.title || "",
      selectedForm: null,
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 15,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
      isLoading: false,
      loadingData: true,
      draggingOver: false,
      model: "",
      useCamera: false,
      loadQR: false,

      optionsStats: [
        { id: 1, label: "3 derniers trimestres", value: "3t" },
        { id: 2, label: "3 derniers semestres", value: "3s" },
        { id: 3, label: "3 dernières années", value: "3a" },
      ],
      selectedStats: { id: 3, label: "3 dernières années", value: "3a" },
      slug: null,
      isAgentPolice: null,
      isCommissaire: null,
      isSuperAdmin: null,

      totalRows: 0,

      months: utilsService.getListMonths(),
      years: utilsService.getListYears(),
    };
  },

  computed: {
    estUneFicheCliente() {
      return this.selectedForm && this.selectedForm.type === "client";
    },
    estUneFicheStatMensuel() {
      return this.selectedForm && this.selectedForm.type === "etablissement";
    },
    estUneFicheFrontiere() {
      return this.selectedForm && this.selectedForm.sousType === "frontiere";
    },
    estUneFicheFrontiereEntree() {
      return this.selectedForm && this.selectedForm.transit === "entree";
    },
    estUneFicheFrontiereSortie() {
      return this.selectedForm && this.selectedForm.transit === "sortie";
    },
    isAdminOrAgentMTCA() {
      const { currentUserId, userRole } = utilsService.currentUserUtils();
      return userRole.code === "agent_mtca" || userRole.code === "super_admin";
    },
    isPromoteur() {
      const { currentUserId, userRole } = utilsService.currentUserUtils();
      return userRole.code === "promo_etablissement";
    },
    isAgent() {
      const { currentUserId, userRole } = utilsService.currentUserUtils();
      return userRole.code === "agent_etablissement";
    },
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        return;
        const { slug } = val.params;
        this.slug = slug;

        this.selectedForm = utilsService.getFormulaireBySlug(slug);
        this.formTitle = utilsService.getFormulaireTitleBySlug(slug);

        const { isAgentPolice, isCommissaire, isSuperAdmin, userData } =
          utilsService.currentUserUtils();
        this.isAgentPolice = isAgentPolice;
        this.isCommissaire = isCommissaire;
        this.isSuperAdmin = isSuperAdmin;

        // add column
        this.tableColumns = [];
        this.tableColumns.push({
          key: "reference",
          stickyColumn: true,
          label: "Ref",
          sortable: true,
        });
        // add columns by type form type
        const { type, sousType } = this.selectedForm;

        if (this.isPromoteur || this.isAgent) {
          this.query.options = {
            etablissement: userData.etablissement._id,
          };
        }
        // frontiere
        if (sousType === "frontiere") {
          if (isAgentPolice) {
            this.query.options = {
              commissariat: userData.commissariatAssociated._id,
              auteur: userData._id,
            };
          }
          if (isCommissaire) {
            this.query.options = {
              commissariat: userData._id,
            };
            this.tableColumns.push({
              key: "auteur",
              stickyColumn: true,
              label: "Agent Enquêteur",
              sortable: true,
            });
          }
          this.tableColumns.push(
            {
              key: "lastname",
              stickyColumn: true,
              label: "Nom Voyageur",
              sortable: true,
            },
            {
              key: "firstname",
              stickyColumn: true,
              label: "Prénom Voyageur",
              sortable: true,
            },
            {
              key: "date",
              stickyColumn: true,
              label: "Date d'entrée",
              sortable: true,
            }
          );
          if (this.estUneFicheFrontiereEntree) {
            this.tableColumns.push({
              key: "date",
              stickyColumn: true,
              label: "Date d'entrée",
              sortable: true,
            });
          }
          if (this.estUneFicheFrontiereSortie) {
            this.tableColumns.push({
              key: "date",
              stickyColumn: true,
              label: "Date de sortie",
              sortable: true,
            });
          }
        }
        if (type === "etablissement") {
          this.tableColumns.push(
            {
              key: "nom",
              stickyColumn: true,
              label: "Nom Établissement",
              sortable: true,
            },
            {
              key: "annee",
              stickyColumn: true,
              label: "Année",
              sortable: true,
            },
            {
              key: "mois",
              stickyColumn: true,
              label: "Mois",
              sortable: true,
            },
            {
              key: "statut",
              stickyColumn: true,
              label: "Statut Validation",
              sortable: true,
            }
          );
        }
        if (type === "client") {
          this.tableColumns.push(
            {
              key: "lastname",
              stickyColumn: true,
              label: "Nom Client",
              sortable: true,
            },
            {
              key: "firstname",
              stickyColumn: true,
              label: "Prénom Client",
              sortable: true,
            },
            {
              key: "dateArrivee",
              stickyColumn: true,
              label: "Date d'arrivée du client",
              sortable: true,
            }
          );
          if (!this.isEtablissementUser) {
            this.tableColumns.push({
              key: "etablissement",
              label: "établissement",
              sortable: true,
              stickyColumn: true,
            });
          }
        }
        this.tableColumns.push(
          {
            key: "createdAt",
            stickyColumn: true,
            label: "Date d'ajout",
            sortable: true,
          },
          { key: "actions", stickyColumn: true }
        );
        // this.loadingData = true
        if (this.isAdminOrAgentMTCA) {
          await this.getListEtablissements();
          this.getListCommissariats();
        }
        this.getListFormulaires();
      },
    },
    "paginationData.currentPage": {
      handler(val, old) {
        this.getListFormulaires();
      },
    },
    "paginationData.perPage": {
      handler(val, old) {
        this.getListFormulaires();
      },
    },
    // watch for reset filter ----------------
    "filterBy.periode.from": {
      immediate: false,
      deep: true,
      handler(val, old) {
        if (!val) {
          this.resetListWhenResetFilter();
        }
      },
    },
    "filterBy.nomEtablissement": {
      immediate: false,
      deep: true,
      handler(val, old) {
        if (!val) {
          this.resetListWhenResetFilter();
        }
      },
    },
    "filterBy.commissariat": {
      immediate: false,
      deep: true,
      handler(val, old) {
        if (!val) {
          this.resetListWhenResetFilter();
        }
      },
    },
    "filterBy.annee": {
      immediate: false,
      deep: true,
      handler(val, old) {
        if (!val) {
          this.resetListWhenResetFilter();
        }
      },
    },
    "filterBy.mois": {
      immediate: false,
      deep: true,
      handler(val, old) {
        if (!val) {
          this.resetListWhenResetFilter();
        }
      },
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },

  mounted() {},

  methods: {
    ...mapActions("formulaire", {
      fetchFormulaires: "fetchFormulaires",
      exportFormulaires: "exportFormulaires",
    }),
    ...mapActions("etablissements", {
      action_findEtablissement: "findEtablissement",
    }),
    ...mapActions("params", { action_fetchTypesRoles: "fetchTypesRoles" }),
    ...mapActions("users", {
      action_fetchUsers: "fetchUsers",
      actions_fetchCommissariats: "fetchCommissariats",
    }),
    // ----------------------------------------------------------------------
    getListEtablissements() {
      this.action_findEtablissement({})
        .then((response) => {
          this.etablissementsList = response.data.resources;
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    },
    async getListCommissariats(somesParams) {
      const params = {
        options: {
          // role: userRoleId,
        },
        page: this.paginationData.currentPage - 1,
        perPage: 1000,
      };
      this.actions_fetchCommissariats(params)
        .then(async (response) => {
          this.tableDataCommissariats = response.data.resources || [];
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    getTableHeaderForName(item) {
      const type = this.selectedForm && this.selectedForm.type;
      if (type === "etablissement") return "Nom établissement";
      if (type === "grand_public") return "Nom";
      if (type === "client") return "Nom";
      return "Nom";
    },
    getTableCellContentForNameColumn(item) {
      const { type, sousType } = this.selectedForm;

      if (type === "etablissement") return item.nomEtablissement;
      if (type === "grand_public") {
        if (sousType === "aeroport") return item.fullname;
        if (sousType === "frontiere") return item.lastname;
        return item.lastname;
      }
      if (type === "client") return item.lastname;
      return item.nomEtablissement || item.lastname;
    },
    getTableCellContentFoProfileColumn(item) {
      const { auteur, auteurRole } = item;
      if (!auteur) return "Grand public";
      return (auteurRole && auteurRole.nom) || "-";
    },
    createFormulaire() {
      const { params } = this.selectedForm.createRouteProps;
      this.$router.push({
        ...this.selectedForm.createRouteProps,
        params: {
          ...params,
        },
      });
    },
    canCreateForm() {
      const { resource } = this.selectedForm;
      return this.$can("create", resource);
    },
    canViewFormDetails() {
      const { resource } = this.selectedForm;
      return this.$can("read", resource);
    },
    async getListFormulaires() {
      this.updateFilter();
      const params = { ...this.query };
      this.tableData = [];
      this.isLoading = true;
      if (!this.selectedForm) return;
      const { apiRoutePrefixe } = this.selectedForm;
      if (!apiRoutePrefixe) return;
      this.model = apiRoutePrefixe;

      let filterLength = params.filter.type.length;
      let foundEtablissement = false;
      if (!this.isPromoteur || !this.isAgent || filterLength != 1) {
        let indexFound = null;
        params.filter.type.map((element, index) => {
          if (element == "etablissement") {
            indexFound = index;
          }
        });
        if (indexFound) {
          params.filter.type.splice(indexFound, 1);
          params.filter.text.splice(indexFound, 1);
        }
      }
      await this.fetchFormulaires({
        formType: apiRoutePrefixe,
        params:
          {
            ...params,
            page: this.paginationData.currentPage - 1,
            perPage: this.paginationData.perPage,
          } || null,
      })
        .then((res) => {
          this.isLoading = false;

          if (res.status == 200) {
            this.tableData = [];
            this.tableData = res.data.resources;
            this.totalRows = res.data.total;

            this.paginationData.total =
              res.data.total || res.data.resources.length || 0;
            if (this.paginationData.metaData.from == 0) {
              this.paginationData.metaData.from += 1;
            } else {
              this.paginationData.metaData.from =
                this.paginationData.perPage * this.paginationData.currentPage -
                this.paginationData.perPage;
            }
            this.paginationData.metaData.to =
              this.paginationData.perPage * this.paginationData.currentPage -
              this.paginationData.perPage +
              this.tableData.length;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    },
    uploadData(action) {
      this.loadingExport = true;
      const { apiRoutePrefixe } = this.selectedForm;
      if (!apiRoutePrefixe) {
        this.loadingExport = false;
        return;
      }
      if (action === "allData") {
        this.query.filter.type = [];
        this.query.filter.text = [];
      }
      if (action === "filteredData") {
        haveFilter = true;
        this.updateFilter();
      }
      this.exportFormulaires({
        formType: apiRoutePrefixe,
        params: { ...this.query },
      })
        .then(async (res) => {
          if (res.status == 200) {
            this.loadingExport = false;
            try {
              const blob = res.data;
              const fileName = `${this.slug}.csv`;
              const newBlob = new Blob([blob]);
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
              } else {
                // For other browsers: create a link pointing to the ObjectURL containing the blob.
                const objUrl = window.URL.createObjectURL(newBlob);
                const link = document.createElement("a");
                link.href = objUrl;
                link.download = fileName;
                link.click();
                // For Firefox it is necessary to delay revoking the ObjectURL.
                setTimeout(() => {
                  window.URL.revokeObjectURL(objUrl);
                }, 250);
              }
            } catch (error) {
              console.log("error: ", error);
            }
          }
        })
        .catch((err) => {
          this.loadingExport = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    updateFilter() {
      this.query.filter = {
        text: [],
        type: [],
      };

      const etablissementsFilter = () => {
        const { nomEtablissement, _id } = this.filterBy.nomEtablissement || {};
        return {
          [this.estUneFicheCliente ? "etablissement" : "nomEtablissement"]: this
            .estUneFicheCliente
            ? _id || ""
            : nomEtablissement || "",
        };
      };
      const periodeFilter = () => {
        const { from, to } = this.filterBy.periode;
        if (!from && !to) return { createdAt: "" };
        const result = !to ? `${from},${from}` : `${from},${to}`;
        return { createdAt: result };
      };

      /**
       *! Make sure that only data that belongs to the connected user will be retrieved
       *! Its applied to etablissement users only
       */
      const abilityFilter = () => {
        const {
          isEtablissementUser,
          etablissementId,
          isCommissariatUser,
          isCommissaire,
          currentUserId,
        } = utilsService.currentUserUtils();
        if (isCommissariatUser) return { commissariat: currentUserId };
        return {};
      };

      let filters = {
        ...(this.filterBy || {}),
      };

      delete filters.periode;
      delete filters.nomEtablissement;
      filters = {
        ...filters,
        ...etablissementsFilter(),
        ...periodeFilter(),
        ...abilityFilter(),
        commissariat: this.filterBy.commissariat,
        dateArrivee: this.reverseDate(this.filterBy.dateArrivee),
        date: this.reverseDate(this.filterBy.date),
      };

      const filtersObj = buildFiltersForRequest(filters);

      this.query = {
        ...this.query,
        filter: filtersObj,
      };
    },
    search() {},

    onDecode(decodedString) {
      if (decodedString) {
        this.loadQR = true;
        const params = decodedString.split("|");
        this.$router.push({
          name: "formulaires-details",
          params: { id: params[0], model: this.selectedForm.apiRoutePrefixe },
        });
      }
    },
    onDragOver(draggingOver) {
      this.draggingOver = draggingOver;
    },
    onPaginationChange(val) {
      this.paginationData = { ...this.paginationData, ...val };
    },

    async fetchDatasForStats(page, perPage) {
      if (!this.selectedForm) return;
      const { apiRoutePrefixe } = this.selectedForm;
      const apiRoutePrefixeWithoutDash = apiRoutePrefixe.replace("-", "");
      if (!apiRoutePrefixe) return;
      await this.fetchFormulaires({
        formType: apiRoutePrefixe,
        params: { page, perPage } || null,
      })
        .then((res) => {
          this.statsLoad = res.data.resources;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetListWhenResetFilter() {
      this.getListFormulaires();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "@core/scss/base/pages/app-invoice.scss";
.per-page-selector {
  width: 90px;
}

.main-grid {
  display: grid;
  grid-template-columns: auto 400px;
  column-gap: 30px;
  position: relative;
  .options-sidebar {
    background: #fff;
    overflow: auto;
    height: 80vh;
    padding-bottom: 59px;
    position: fixed;
    right: 1.5rem;
    width: 400px;
  }
}
.v-select {
  width: 100%;
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
  .main-grid {
    display: unset;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 30px;
    position: relative;
    .options-sidebar {
      overflow: auto;
      height: unset;
      position: unset;
      width: unset;
    }
  }
}
.d-content {
  display: contents;
}
#sidebar-wrapper {
  position: fixed;
  right: 250px;
  width: 100%;
  height: 100%;
  margin-right: -90%;
  overflow-y: auto;
  .sidecard {
    margin: 0 0 800px 0;
  }
}

@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }
      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
