<template>
  <!-- spinner -->
  <date-picker
    v-model="range"
    range-separator=" au "
    format="DD-MM-YYYY"
    :placeholder="customplaceholder"
    value-type="YYYY/MM/DD"
    range
    append-to-body
    lang="fr"
    confirm
    @clear="clear"
    @confirm="confirm"
  />
  
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/fr'
import 'vue2-datepicker/index.css'
import moment from 'moment'

export default {
  components: {
    DatePicker,
  },
  props: {
    daterange: {
      default: '',
      type: String,
    },
    customplaceholder: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      range: '',
      

    }
  },
  watch: {
    daterange: {
      handler(old, val) {
        this.range = val
      },
    },
  },
  methods: {
    confirm(range) {
      this.$emit('input', { from: range[0], to: range[1] })
    },
    clear(range) {
      this.$emit('input', {})
    },

  },
}
</script>
<style lang="scss" scoped>
.loader {
  margin: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 300px;
  height: 100vh;
  width: calc(100vw - 310px);
  background-color: #000000d6;
  z-index: 10000000;
  filter: alpha(opacity=40);
}

.default {
  width: 100vw !important;
  left: 0 !important;
}

.message {
  margin-top: 50px;
  font-size: 26px;
  color: white;
}

.mx-datepicker,
.mx-datepicker-range {
  width: 100% !important;
}
</style>
